<template>
  <div class="property-tags-add">
    <fd-form class="card py-1 px-2" @submit.prevent="submitForm">
      <div class="py-2 px-1">
        <h4 class="mainTitle">Add Tag</h4>
      </div>
      <div class="main-contents">
        <banner class="bg-secondary fg-white">
          Tag must be in lower case and combination of alphabet and number.
        </banner>
        <fd-input
          class="mt-2"
          type="text"
          :label="`Tag Name`"
          v-model="propertyTag.name"
          @blur="propertyTag.name = propertyTag.name.toLowerCase()"
          :validators="[required, alphanumeric]"
        />
      </div>
      <div class="text-right px-2 mt-4 mb-2">
        <fd-button class="mr-1" @click="$emit('close')">Close</fd-button>
        <fd-button type="submit" class="main">Create</fd-button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import "@/assets/GlobalStyling/static-css/material-colors/colors.css";
import { PropertyTagModel } from "@/models";
import {
  required,
  alphanumeric
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  name: "property-tags-add",
  components: {
    Banner: () => import("@/components/GlobalComponents/Banner")
  },
  props: {},
  data() {
    return {
      propertyTag: {
        name: ""
      },

      required,
      alphanumeric
    };
  },
  methods: {
    async isDuplicated(name) {
      let data = await this.$store.dispatch(
        "managePropertyTags/getAllPropertyTags",
        {
          name: name
        }
      );
      return data.data.length > 0;
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true, { root: true });
      this.propertyTag.name = this.propertyTag.name.toLowerCase();

      let isDup = await this.isDuplicated(this.propertyTag.name);

      if (isDup) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Duplicated Tag",
          text: `Tag ${this.propertyTag.name} has already existed.`
        });
      } else {
        try {
          await this.$store.dispatch(
            "managePropertyTags/createPropertyTags",
            PropertyTagModel.postPropertyTagDetailPayload(this.propertyTag)
          );
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: " New property tags created successfully."
          });
          this.$emit("added");

          this.$store.commit("setIsLoading", false, { root: true });
          return;
        } catch (error) {
          this.$store.commit("setIsLoading", false, { root: true });
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: " An unexpected error occured. Please try again later."
          });
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss">
</style>